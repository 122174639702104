<!-- 用户生命周期 -->
<template>
  <section class="cont lifecycle">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <div class="table-head-btn">
          <el-radio-group v-model="dateType">
            <el-radio-button :label="-1">昨日</el-radio-button>
            <el-radio-button :label="7">七天</el-radio-button>
            <el-radio-button :label="30">30天</el-radio-button>
            <!-- <el-radio-button :label="0">选择日期</el-radio-button> -->
          </el-radio-group>
          <!-- <el-date-picker v-show="dateType === 0" v-model="date" type="daterange" unlink-panels value-format="yyyy-MM-dd" range-separator="至" 
            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" :clearable="false" class="m-left-60"></el-date-picker> -->
          <el-row class="right-btn">
            <el-button>小程序</el-button>
          </el-row>
        </div>
      </el-row>
      <div class="font-18">
        <p class="m-top-10">用户生命周期</p>
        <p class="m-top-5" v-if="dateType === -1">{{ yesterday }}</p>
        <p class="m-top-5" v-else>{{ date[0] }}~{{ date[1] }}</p>
      </div>
      <div class="card-box m-top-20">
        <div class="card-box-item">
          <p class="item-tit m-bottom-20">新用户：</p>
          <p class="item-text">{{ newMember }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit m-bottom-20">连续活跃用户：</p>
          <p class="item-text">{{ continuityMember }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit m-bottom-20">回流用户：</p>
          <p class="item-text">{{ backflowMember }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit m-bottom-20">沉默用户：</p>
          <p class="item-text">{{ silentMember }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit m-bottom-20">流失用户：</p>
          <p class="item-text">{{ lossMember }}人</p>
        </div>
      </div>
      <div id="myChart"></div>
    </el-row>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { dateFactory } from '@/common/js/common'
  import { getList } from '@/api/business/userStatistics/realTimeStatistics'
  export default {
    name: 'lifecycle',
    data() {
      return {
        crumbs: new Map([['业务中台'], ['实时用户统计'], ['用户生命周期']]),
        dateType: -1,
        yesterday: dateFactory.getDistanceToday(-1, false),
        date: [dateFactory.getDistanceToday(0, false), dateFactory.getDistanceToday(0, false)],

        newMember: 0, // 新用户
        continuityMember: 0, // 连续用户
        backflowMember: 0, // 回流用户
        silentMember: 0, // 沉默用户
        lossMember: 0, // 流失用户

        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
          disabledDate(time) {
            return time.getTime() >= Date.now()
          },
        },
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      dateType(newval) {
        if (newval === 7 || newval === 30) {
          this.date = [dateFactory.getDistanceToday(-newval, false), dateFactory.getDistanceToday(0, false)]
        }
        this.getData()
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        let option = {
          title: {
            text: '用户生命周期',
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['新用户', '连续活跃用户', '回流用户', '沉默用户', '流失用户'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          series: [
            {
              name: '新用户',
              type: 'line',
              stack: 'Total',
              color: '#006fff',
              data: [],
            },
            {
              name: '连续活跃用户',
              type: 'line',
              stack: 'Total',
              color: '#4cd4b5',
              data: [],
            },
            {
              name: '回流用户',
              type: 'line',
              stack: 'Total',
              color: '#f56d69',
              data: [],
            },
            {
              name: '沉默用户',
              type: 'line',
              stack: 'Total',
              color: '#ffa533',
              data: [],
            },
            {
              name: '流失用户',
              type: 'line',
              stack: 'Total',
              color: '#a236fe',
              data: [],
            },
          ],
        }
        const data = {
          companyId: this.hotelInfo.storeId, // 集团ID
          beginTime: this.dateType == -1 ? this.yesterday : this.date[0], // 开始时间
          endTime: dateFactory.getDistanceToday(0, false), // 结束时间
        }
        getList(data).then(({ success, records }) => {
          if (success) {
            this.newMember = 0
            this.continuityMember = 0
            this.backflowMember = 0
            this.silentMember = 0
            this.lossMember = 0
            for (let i = 0; i < records.length; i++) {
              this.newMember += records[i].newMember
              this.continuityMember += records[i].continuityMember
              this.backflowMember += records[i].backflowMember
              this.silentMember += records[i].silentMember
              this.lossMember += records[i].lossMember
              if (this.dateType > 10) {
                option.xAxis.data[i] = records[i].date.split('-')[2] + '日'
              } else {
                option.xAxis.data[i] = records[i].date
              }
              option.series[0].data[i] = records[i].newMember
              option.series[1].data[i] = records[i].continuityMember
              option.series[2].data[i] = records[i].backflowMember
              option.series[3].data[i] = records[i].silentMember
              option.series[4].data[i] = records[i].lossMember
            }
            setTimeout(() => {
              this.$echarts.init(document.getElementById('myChart')).setOption(option)
            }, 200)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .lifecycle {
    .card-box {
      $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
      $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
      $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
      $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
      $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
      $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow, $gradient-red;

      display: flex;
      justify-content: space-between;
      .card-box-item {
        width: 18%;
        padding: 30px 40px;
        background-repeat: no-repeat;
        text-align: left;
        background-position: 0 0;
        background-size: 100% 100%;
        box-sizing: border-box;
        color: #ffffff;
        border-radius: 6px;
        @each $c in $list-bg {
          $i: index($list-bg, $c);
          &:nth-child(#{$i}) {
            background: nth($list-bg, $i);
          }
        }
        .item-tit {
          font-size: 18px;
          font-weight: 400;
        }
        .item-text {
          font-size: 36px;
          font-weight: 700;
        }
      }
    }
    #myChart {
      width: 100%;
      padding-top: 40px;
      height: 500px;
    }
  }
</style>
