import request from '@/api/service'

const api = {
  // 实时统计
  getMemberHotelTotal: '/member/memberHotel/getMemberHotelTotal',

  // 7天 30天新增用户数量
  getNewUserCountDay: '/member/memberHotel/getNewUserCountDay',
  // 7天 30天用户活跃数量
  getActiveUserCount: '/system/loginLog/getActiveUserCount',
  // 统计30天新增用户
  getNewUsersDay: '/member/memberHotel/getNewUsersDay',
  // 统计30天活跃人数(小程序)
  getUserCountDay: '/system/loginLog/getUserCountDay',

  // 用户生命周期
  getList: '/member/memberLeftTotalLog/getList',
}

// 实时统计
export function getMemberHotelTotal(data) {
  return request({
    url: api.getMemberHotelTotal,
    method: 'post',
    data,
  })
}

// 7天 30天新增用户数量
export function getNewUserCountDay(data) {
  return request({
    url: api.getNewUserCountDay,
    method: 'post',
    data,
  })
}

// 7天 30天用户活跃数量
export function getActiveUserCount(data) {
  return request({
    url: api.getActiveUserCount,
    method: 'post',
    data,
  })
}

// 统计30天新增用户
export function getNewUsersDay(data) {
  return request({
    url: api.getNewUsersDay,
    method: 'post',
    data,
  })
}

// 统计30天活跃人数(小程序)
export function getUserCountDay(data) {
  return request({
    url: api.getUserCountDay,
    method: 'post',
    data,
  })
}

// 用户生命周期
export function getList(data) {
  return request({
    url: api.getList,
    method: 'post',
    data,
  })
}
